@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
   src: url("../fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("../fonts/MaterialIcons-Regular.eot") format('eot'),
    url("../fonts/MaterialIcons-Regular.woff") format("woff"),
    url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("../fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("../fonts/MaterialIcons-Regular.woff") format("woff"),
    url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular-webfont.eot");
  src: url("../fonts/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Regular-webfont.woff") format("woff"),
    url("../fonts/Roboto-Regular-webfont.ttf") format("truetype"),
    url("../fonts/Roboto-Regular-webfont.svg#RobotoRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold-webfont.eot");
  src: url("../fonts/Roboto-Bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Bold-webfont.woff") format("woff"), url("../fonts/Roboto-Bold-webfont.ttf") format("truetype"),
    url("../fonts/Roboto-Bold-webfont.svg#RobotoBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin-webfont.eot");
  src: url("../fonts/Roboto-Thin-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Thin-webfont.woff") format("woff"), url("../fonts/Roboto-Thin-webfont.ttf") format("truetype"),
    url("../fonts/Roboto-Thin-webfont.svg#RobotoThin") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light-webfont.eot");
  src: url("../fonts/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Light-webfont.woff") format("woff"),
    url("../fonts/Roboto-Light-webfont.ttf") format("truetype"),
    url("../fonts/Roboto-Light-webfont.svg#RobotoLight") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium-webfont.eot");
  src: url("../fonts/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Medium-webfont.woff") format("woff"),
    url("../fonts/Roboto-Medium-webfont.ttf") format("truetype"),
    url("../fonts/Roboto-Medium-webfont.svg#RobotoMedium") format("svg");
  font-weight: 300;
  font-style: normal;
}

* {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

html,
body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
  margin: 0;
}

body {
  background:#359ab3;
}

.main-container {
  margin-top: calc(56px);
  margin-left: calc(250px + 8px);
  margin-bottom: 0;
  margin-right: 0;
  display: block;
  padding: 40px;
  background: #f5f5f5;
  min-height: calc(100vh - 136px);
}

.mb16 {
  margin-bottom: 16px;
}
.mt0 {
  margin-top: 0px;
}

/*start style for help text*/
.help_text_style {
  line-height: 24px;
  color: #000000;
}
.info-text {
  p,
  li {
    font-size: 15px;
    line-height: 24px;
    color: rgba(#000000, 0.87);
  }

  .highlight-text-primary {
    color: #359ab3;
    font-weight: 600;
  }

  .highlight-text-accent {
    color:#aa08de;
  }

  ul {
    list-style: none;
  }

  ul li::before {
    font-size: 16px;
    line-height: 24px;
    content: "\2022";
    color: #246281;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  a,
  a:hover,
  a:visited {
    font-size: 15px;
    color:#aa08de;
    }
}
/*end style for help text*/

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}


@media screen and (max-width: 992px) {
  .main-container {
    margin: calc(24px + 56px) 12px 12px 12px;
  }
}

.spacer {
  flex: 1 1 auto;
}

#spinner {
  background-color: rgba(#000, 0.24) !important;
}

.header-lable{
  font-size: 22px;
}

.system_setup{
  font-size: 17px;
  color: #359ab3;
}

.word-break{
  word-break: break-all;
}
.multiple-select{
  span{
    display: inline-block !important;
  }
}
